import React, { Component } from 'react';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom"
import NotFound404 from './pages/notFound404';
import Navbar from './components/navbar';
import Footer from './components/footer';
import InfoFooter from './components/infoFooter';
import HomePage from './pages/homepage'
import Contact from './pages/contact'
import Hosting from './pages/hosting'
import OnDemandManufacturing from './pages/onDemandManufacturing'
import ModularRobotics from './pages/modularRobotics'
import HostingPartner from './pages/hostingPartner'
import Newsletters from './pages/newsletters'

import './style.css'
import PublicRoute from './routes/publicRoute'
class App extends Component {
  render() 
  {
    return (
      <Router >

        <Navbar/> 
        {/* <Footer/>  */}
        <div style={{paddingTop: '88px', overflow: "hidden"}}>
        <Switch>
          //pages accessible for non logged in users
          <PublicRoute exact={true} path="/"> <HomePage/> </PublicRoute>
          <PublicRoute exact={true} path="/hosting"><Hosting/></PublicRoute>    
          <PublicRoute exact={true} path="/hosting/partner"><HostingPartner/></PublicRoute>  
          <PublicRoute exact={true} path="/on-demand-manufacturing"><OnDemandManufacturing/></PublicRoute>      
          <PublicRoute exact={true} path="/modular-robotics"><ModularRobotics/></PublicRoute>      
          <PublicRoute exact={true} path="/contact"><Contact/></PublicRoute>    
          <PublicRoute exact={true} path="/newsletters"><Newsletters/></PublicRoute>    
          <Route><NotFound404/></Route>
        </Switch>
        {/* <hr style={{width: "90%"}}/> */}
        <div style={{background: "#343a40", paddingTop: "20px", color: "white"}}>
        <InfoFooter/> 
        </div>
        </div>
      </Router>
    );
  }
}
 


export default App;