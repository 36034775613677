import React, { Component } from 'react';
import { Row} from 'react-bootstrap'


class OnDemandManufacturing extends Component {
    state = { 
        width:1920,
        height: 1080
     }
    scroll = () => {
        let element = document.getElementById("content")
        element.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});

    }
    componentDidMount(){
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
      }
    updateWindowDimensions=() =>{
        this.setState({ width: window.screen.availWidth, height: window.innerHeight });
      }
    render() { 
        
        let width = "0"
        let height = "0"
        let noScreen=false
        if(this.state.width > 620){
            width = "600"
            height = "200"
        }else if(this.state.width > 415){
            width = "400"
            height = "200"
        }
        else if(this.state.width > 310){
            width = "300"
            height = "200"
        }else{
            noScreen=true
        }
        return ( 
            <div style={{minHeight: '100%'}}>
             
                    {!noScreen &&<div style={{margin: 'auto', height: 'auto', width: '100%', position: 'relative', textAlign: 'center', background: '#212529'}}>
                    <img alt='banner' style={{maxWidth: '100%', maxHeight: '720px', minHeight: '350px'}}  src={require('../printer-2416270_1920.jpg')}></img>


                    <div style={{margin: 'auto', height: height +'px',position: 'absolute', top: '45%', left: '50%', transform: 'translate(-50%, -50%)', width: width + "px", borderRadius: '20px', borderStyle: 'solid', borderColor: '#343a40', backgroundColor:'rgba(255,255,255,0.8)'}}>
                            <div style={{position: 'absolute', top: '45%',width:width + "px", left: '50%', transform: 'translate(-50%, -50%)'}}>
                                <h1 style={{textAlign:'center'}}><u>On Demand Manufacturing</u></h1>
                                <h2 style={{textAlign:'center'}}><u>Coming Soon</u></h2>
                            </div>
                    </div>
                    
                </div>}
                {noScreen && <div>
                    <h1 style={{textAlign:'center'}}><u>On Demand Manufacturing</u></h1>
                    <h2 style={{textAlign:'center'}}><u>Coming Soon</u></h2>
                </div> }

               
                <div style={{background:'white', paddingTop: '10px', paddingBottom: '150px',maxWidth: '1280px', margin: 'auto'}}>
                    
                    <div style={{margin: 'auto',width: '75%', textAlign: 'center',paddingBottom: '10px'}}>
                        <Row style={{padding: '10px'}}>
                            <h2 style={{margin: 'auto', textAlign: 'center'}}>
                                <u>Features</u>
                            </h2>
                        </Row>
                        <Row style={{padding: '10px'}}>
                            <h4 style={{margin: 'auto', textAlign: 'center'}}>
                                <u>Low Costs</u>
                            </h4>
                        </Row>
                        <Row style={{padding: '10px'}}>
                            <p style={{margin: 'auto', textAlign: 'center'}}>
                                Todays on demand manufacturing costs are far higher than needed. We bring that price down to an affordable price to allow everyone from hobbyists to large companies to get there items manufactured.
                            </p>
                        </Row>
                        <Row style={{padding: '10px'}}>
                            <h4 style={{margin: 'auto', textAlign: 'center'}}>
                                <u>No Minimum Units</u>
                            </h4>
                        </Row>
                        <Row style={{padding: '10px'}}>
                            <p style={{margin: 'auto', textAlign: 'center'}}>
                                With no minimum units needed for manufacturing, you can get anything from just one item manufactured to hundreds or thousands. This makes the on demand manufacturing market accessible to small companies or individuals not just large companies.
                            </p>
                        </Row>
                        <Row style={{padding: '10px'}}>
                            <h4 style={{margin: 'auto', textAlign: 'center'}}>
                                <u>High Quality CNC machines</u>
                            </h4>
                        </Row>
                        <Row style={{padding: '10px'}}>
                            <p style={{margin: 'auto', textAlign: 'center'}}>
                                Our range of high quality CNC machines with highly trained technicians allows for manufacturing in a wide range of materials and processes.
                            </p>
                        </Row>
                        <Row style={{padding: '10px'}}>
                            <h4 style={{margin: 'auto', textAlign: 'center'}}>
                                <u>Customisable</u>
                            </h4>
                        </Row>
                        <Row style={{padding: '10px'}}>
                            <p style={{margin: 'auto', textAlign: 'center'}}>
                                If youve got specific manufacturing parameters and requirements, simply state them or change the settings on the manufacturing console. If you need assistance with settings or what type of process to manufacture in, simply use our 
                                recommended settings or get help from our friendly technicians.
                            </p>
                        </Row>
                        <Row style={{padding: '10px'}}>
                            <h4 style={{margin: 'auto', textAlign: 'center'}}>
                                <u>Fast production and shipping times</u>
                            </h4>
                        </Row>
                        <Row style={{padding: '10px'}}>
                            <p style={{margin: 'auto', textAlign: 'center'}}>
                                Our 24/7 CNC manfucaturing and advanced algorithms allow for items to be manufactured in the most efficient and effective way allowing for fast production and shipping times.
                            </p>
                        </Row>
                        <Row style={{padding: '10px'}}>
                            <h4 style={{margin: 'auto', textAlign: 'center'}}>
                                <u>Easy control pannel</u>
                            </h4>
                        </Row>
                        <Row style={{padding: '10px'}}>
                            <p style={{margin: 'auto', textAlign: 'center'}}>
                                Weve custom made our control pannel to make it easy to use, yet powerful. Simply upload the model of the item you want manufactured, select the process and material and finally fine tune the settings or choose one of our reccomended profiles. After payment, you will be able to 
                                view what stage your product is at in manufacturing and shipping so that you will always be in control from start to finish.
                            </p>
                        </Row>
                                             
                    </div>
                </div>                
            </div>
        );
    }
}
 
export default OnDemandManufacturing;
