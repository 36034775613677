import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PrivacyPolicy from './privacyPolicy';
import PurchasePolicy from './purchaseCancellationRefundPolicy';

class InfoFooter extends Component {
  state = {
    showPurchasePolicy: false,
    showPolicy: false
  }

  closePolicy = () => {
    this.setState({ showPolicy: false })
  }
  openPolicy = () => {
    this.setState({ showPolicy: true })
  }
  closePurchasePolicy = () => {
    this.setState({ showPurchasePolicy: false })
  }
  openPurchasePolicy = () => {
    this.setState({ showPurchasePolicy: true })
  }
  underscore = (type) => {
    this.setState({ hovered: type })
  }
  underscoreClear = () => {
    this.setState({ hovered: null })
  }
  render() {
    let footerInfoPadding
    if (window.innerWidth > 1023) {
      footerInfoPadding = "50px"
    } else if (window.innerWidth > 576) {
      footerInfoPadding = "70px"
    } else if (window.innerWidth > 506) {
      footerInfoPadding = "100px"
    } else if (window.innerWidth > 341) {
      footerInfoPadding = "120px"
    } else if (window.innerWidth > 293) {
      footerInfoPadding = "140px"
    } else {
      footerInfoPadding = "170px"
    }
    return (
      <div>
        <div style={{ paddingBottom: footerInfoPadding, width: "90%", margin: 'auto' }}>
          <Row style={{ marginLeft: '10px' }}>
            <Col>
              <h4>Services</h4>
              <div><a href="https://breezelabs.co.uk/hosting" style={{ color: 'white' }}>Hosting</a></div>
              <div><a href="https://breezelabs.co.uk/on-demand-manufacturing" style={{ color: 'white' }}>On Demand Manufacturing</a></div>
              <div><a href="https://breezelabs.co.uk/modular-robotics" style={{ color: 'white' }}>Modular Robotics</a></div>
            </Col>
            <Col>
              <h4>About</h4>
              <div><a href="https://breezelabs.co.uk/newsletters" style={{ color: 'white' }}>Newsletters</a></div>
              <div><a href="https://breezelabs.co.uk/contact" style={{ color: 'white' }}>Contact Us</a></div>
              <div><a href="https://breezelabs.co.uk/hosting/partner" style={{ color: 'white' }}>Hosting Partner Programme</a></div>
            </Col>
            <Col>
              <h4>Policies</h4>
              <div><a onMouseEnter={() => this.underscore("privacy")} onMouseLeave={() => this.underscoreClear()} onClick={this.openPolicy} style={{ color: 'white', textDecoration: this.state.hovered === "privacy" ? "underline" : "none" }}>Privacy Policy</a></div>
              <div><a onMouseEnter={() => this.underscore("purchasepol")} onMouseLeave={() => this.underscoreClear()} onClick={this.openPurchasePolicy} style={{ color: 'white', textDecoration: this.state.hovered === "purchasepol" ? "underline" : "none" }}>Purchase, Return and Refund Policy</a></div>
            </Col>
          </Row>
          <PrivacyPolicy showPolicy={this.state.showPolicy} closePolicy={this.closePolicy} openPolicy={this.openPolicy} />
          <PurchasePolicy showPolicy={this.state.showPurchasePolicy} closePolicy={this.closePurchasePolicy} openPolicy={this.openPurchasePolicy} />

        </div>
          <Row style={{ width: "100%", padding: "20px" }}>
            <Col xs={6} style={{ margin: 'auto' }}>
              <p style={{ color: 'white', margin: 'auto' }}>&copy; 2021 BreezeLabs. All Rights Reserved.</p>

            </Col>
            <Col xs={6} style={{ margin: 'auto', textAlign: "right" }}>
              <a target='_blank' style={{ margin: 'auto', padding: '5px' }} href='https://twitter.com/breezelabsuk'><img alt='twitter' style={{ width: '20px' }} src={require('../twitter.png')}></img></a>
              <a target='_blank' style={{ margin: 'auto', padding: '5px' }} href='https://facebook.com/BreezeLabs-103161455084732'><img alt='facebook' style={{ width: '20px' }} src={require('../facebook.png')}></img></a>
            </Col>
   
          </Row>

      </div>

    );
  }
}

export default InfoFooter;