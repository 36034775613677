import React, { Component } from 'react';
import {Row, Col, Button, Form, Card} from 'react-bootstrap'
import Axios from 'axios';
class Hosting extends Component {
    state = { 
        email: '',
        number: '',
        message: '',
        contactEmail: false,
        contactText: false,
        contactTelephone: false,
        submitEnabled: false,
        enquirySent: false
     }
     textChange=(e, type)=>{
         e.persist()
         let email = type === 'email' ? e.target.value : this.state.email 
         let messageExists = type === 'message' ? e.target.value !== '' : this.state.message !== ''
         let emailValid = this.validateEmail(email)
         let submitEnabled = emailValid && messageExists 
         this.setState({[type]: e.target.value, submitEnabled: submitEnabled, enquirySent: false})
     }
     handleSubmit = ()=>{
         Axios.post('/api/email/enquiry', this.state).then((res)=>{
            this.setState({
                enquirySent: res.data.success,
                email: '',
                number: '',
                message: '',
                contactEmail: false,
                contactText: false,
                contactTelephone: false,
                submitEnabled: false
            })  
         })
     }
     contactAuthChange=(e, type)=>{
        this.setState({[type]: !this.state[type]})
     }
     validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
     }
    render() { 
        return ( 
            <div style={{minHeight: '100%'}}>
                <div style={{margin: 'auto', height: 'auto', width: '100%', position: 'relative', textAlign: 'center', background: '#212529'}}>
                    <img alt='banner' style={{maxWidth: '100%', maxHeight: '720px'}}  src={require('../pexels-pixabay-356079.jpg')}></img>                    
                </div>
                <div style={{background:'white', paddingTop: '10px', paddingBottom: '100px',maxWidth: '1280px', margin: 'auto'}}>
                <Card style={{width: '80%',margin: 'auto', display: 'block', marginTop: '60px', marginBottom: '60px'}}>
                <Card.Header style={{marginBottom: '10px',textAlign: 'center', background: "#343a40", color: "white"}}>
                    <h2>Contact Us</h2>
                </Card.Header>
                <div >
                    <Row style={{maxWidth: '1000px', margin:'auto'}}>
                        <Col xs={2}style={{minWidth:'180px'}} >
                            <p >Email (required):</p>
                        </Col>
                        <Col style={{minWidth: '250px'}}>
                            <input style={{width: '100%'}} type='text' value={this.state.email} onChange={(e)=>this.textChange(e, 'email')} placeholder='email@email.com' />
                        </Col>
                    </Row>
                    <Row style={{maxWidth: '1000px', margin:'auto', marginTop:'20px'}}>
                        <Col xs={2}style={{minWidth:'180px'}} >
                            <p>Phone Number:</p>
                        </Col>
                        <Col style={{minWidth: '250px'}}>
                            <input style={{width: '100%'}} type='text' value={this.state.number} onChange={(e)=>this.textChange(e, 'number')} placeholder='+44 1234 123456' />
                        </Col>
                    </Row>
                    <Row style={{maxWidth: '1000px', margin:'auto', marginTop:'20px'}}>
                    <Col xs={2}style={{minWidth:'180px'}} >
                            <p>Message (required):</p>
                        </Col>
                        <Col style={{minWidth: '250px'}}>
                            <textarea style={{width: '100%', height: '200px'}} type='text' value={this.state.message} onChange={(e)=>this.textChange(e, 'message')} placeholder='How do i find out more?' />
                        </Col>
                    </Row>
                    <hr/>

                    <Row style={{maxWidth: '1000px', margin:'auto', marginTop:'20px', textAlign: 'center'}}>
                        <Col>
                            <p>Here at BreezeLabs we take your privacy seriously and 
                            will only use your personal information to administer 
                            your account and to provide the products and services 
                            you have requested from us.
                            </p>
                            <p>However, from time to time we would like to contact 
                            you with details of other products, offers, services 
                            and competitions we provide. If you consent to us contacting 
                            you for this purpose, please select the ways you would like us
                            to contact you:
                            </p>
                            <Row style={{padding: '10px'}}>
                                <Col style={{textAlign: 'center'}} xs={4}>
                                    <p>Email</p>
                                    <Form.Control checked={this.state.contactEmail} onChange={(e)=>{this.contactAuthChange(e, 'contactEmail')}} type="checkbox"/>
                                </Col>
                                <Col style={{textAlign: 'center'}} xs={4}>
                                    <p>Phone</p>
                                    <Form.Control checked={this.state.contactTelephone}  onChange={(e)=>{this.contactAuthChange(e, 'contactTelephone')}} type="checkbox"/>
                                </Col>
                                <Col style={{textAlign: 'center'}} xs={4}>
                                    <p>Text</p>
                                    <Form.Control checked={this.state.contactText}  onChange={(e)=>{this.contactAuthChange(e, 'contactText')}} type="checkbox"/>
                                </Col>
                                
                            </Row>                                 
                            <p>If you would prefer not to hear from us, you can stop receiving our updates at any time by emailing us at <a href='mailto:breezelabsuk@gmail.com'>breezelabsuk@gmail.com</a>.</p>
                                    
                        </Col>
                    </Row>
                    </div>
                    <Card.Footer style={{background: "#343a40", color: "white"}}>
                        <Row style={{textAlign: 'center'}}>
                            <Col style={{minWidth: '90px'}}>
                                {this.state.enquirySent  && <p>Enquiry Sent!</p>}
                                {!this.state.submitEnabled && !this.state.enquirySent && <p>Please fill out the required fields!</p>}
                            </Col>
                            <Col style={{}}>
                                <Button onClick={this.handleSubmit} disabled={!this.state.submitEnabled} style={{width: '200px',backgroundColor: 'rgb(0, 123, 255)'}}>Submit</Button>
                            </Col>
                            
                        </Row>    
                    </Card.Footer>
                    </Card>
                </div> 
            </div>
            
         );
    }
}
 
export default Hosting;