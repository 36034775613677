import React, { Component } from 'react';
import './modal.css';

class Modal extends Component {
    render() {
        if (!this.props.show) {
            return null;
        }

        return (
            <div className="bl-modal" onClick={this.props.close}>
                <div className="bl-modal-content" onClick={e => e.stopPropagation()}>
                    <div className="bl-modal-title">{this.props.title}</div>
                    <div className="bl-modal-body">{this.props.content}</div>
                    <div className="bl-modal-footer">
                        <button onClick={this.props.close} className="button">
                            Close
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default Modal;