import Axios from 'axios';
import React, { Component } from 'react';
import _ from 'lodash'

class Newsletters extends Component {
    state = {
        newsletters: [],
        emailTemplate: ''
    }
    componentDidMount() {
        Axios.get('/api/newsletter/get/all').then(res => {
            this.setState({ newsletters: res.data.newsletters })
        })
        this.getTemplates()
    }
    getTemplates = () => {
        Axios.get('/api/newsletter/templates').then((res, err) => {
            const test = _.cloneDeep(res.data.templates.newsletter)
            this.setState({ emailTemplate: test })
        })
    }
    render() {
        let newsletters = []
        this.state.newsletters.forEach((newsletter, i) => {
            let newsletterContent = this.state.emailTemplate.replace(/\$date/g, newsletter.date)
            let titlesHtml = ''
            for (let title in newsletter.titles) { titlesHtml = titlesHtml += `<h5 class='topictitle'>${newsletter.titles[title]}</h5>` }
            let titlePlusContent = ''
            for (let title in newsletter.titles) { 
                titlePlusContent += `<h5 class='contenttitle'>${newsletter.titles[title]}</h5><p class='topiccontent'>${newsletter.content[title]}</p>` 
            }
            newsletterContent = newsletterContent.replace(/\$titles/g, titlesHtml)
            newsletterContent = newsletterContent.replace(/\$content/g, titlePlusContent)
            newsletterContent = newsletterContent.replace(/\$newslettertitle/g, newsletter.newsletterTitle)
            newsletterContent = newsletterContent.replace(/\$issue/g, this.state.newsletters.length - i)
            newsletterContent = newsletterContent.replace(/\$src/g, `src="https://hosting.breezelabs.co.uk/images/logo.png"`)
            newsletterContent = newsletterContent.replace(/<img alt="BreezeLabs" width="100%" style="display: block; margin-left: auto;margin-right: auto" \$bannersrc\/>/g, ``)

            newsletters.push(
                <div dangerouslySetInnerHTML={{ __html: newsletterContent }} />
            )
        })
        return (
            <div style={{ minHeight: '100%', textAlign: 'center' }}>
                <h1 style={{ color: 'black' }}>Newsletters</h1>
                <p style={{ color: 'black', maxWidth: '600px', margin: 'auto' }}>Our newsletters contain information about updates, competitions, announcements and more from us here at BreezeLabs.</p>
                <p style={{ color: 'black', maxWidth: '600px', margin: 'auto' }}>Make sure to enable marketting on your BreezeLabs account to be added to the mailing list to ensure you dont miss out!</p>
                {newsletters}
            </div>
        );
    }
}

export default Newsletters;