import React, { Component } from 'react';
import { Row} from 'react-bootstrap'


class ModularRobotics extends Component {
    state = { 
        width:1920,
        height: 1080
     }
    scroll = () => {
        let element = document.getElementById("content")
        element.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});

    }
    componentDidMount(){
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
      }
    updateWindowDimensions=() =>{
        this.setState({ width: window.screen.availWidth, height: window.innerHeight });
      }
    render() {   
        let width = "0"
        let height = "0"
        let noScreen=false
        if(this.state.width > 620){
            width = "600"
            height = "200"
        }else if(this.state.width > 415){
            width = "400"
            height = "200"
        }
        else if(this.state.width > 310){
            width = "300"
            height = "200"
        }else{
            noScreen=true
        }
        return ( 
            <div style={{minHeight: '100%'}}>
                {!noScreen &&<div style={{margin: 'auto', height: 'auto', width: '100%', position: 'relative', textAlign: 'center', background: '#212529'}}>
                    <img alt='banner' style={{maxWidth: '100%', maxHeight: '720px', minHeight: '350px'}}  src={require('../robot-2791671_1920.jpg')}></img>


                    <div style={{margin: 'auto', height: height +'px',position: 'absolute', top: '45%', left: '50%', transform: 'translate(-50%, -50%)', width: width + "px", borderRadius: '20px', borderStyle: 'solid', borderColor: '#343a40', backgroundColor:'rgba(255,255,255,0.8)'}}>
                            <div style={{position: 'absolute', top: '45%',width:width + "px", left: '50%', transform: 'translate(-50%, -50%)'}}>
                                <h1 style={{textAlign:'center'}}><u>Modular Robotics</u></h1>
                                <h2 style={{textAlign:'center'}}><u>Coming Soon</u></h2>
                            </div>
                    </div>
                    
                </div>}
                {noScreen && <div>
                    <h1 style={{textAlign:'center'}}><u>Modular Robotics</u></h1>
                    <h2 style={{textAlign:'center'}}><u>Coming Soon</u></h2>
                </div> }
          

               
                <div style={{background:'white', paddingTop: '10px', paddingBottom: '150px',maxWidth: '1280px', margin: 'auto'}}>
                    
                    <div style={{margin: 'auto',width: '75%', textAlign: 'center',paddingBottom: '10px'}}>
                        <Row style={{padding: '10px'}}>
                            <h2 style={{margin: 'auto', textAlign: 'center'}}>
                                <u>Features</u>
                            </h2>
                        </Row>
                        <Row style={{padding: '10px'}}>
                            <h4 style={{margin: 'auto', textAlign: 'center'}}>
                                <u>Customisable</u>
                            </h4>
                        </Row>
                        <Row style={{padding: '10px'}}>
                            <p style={{margin: 'auto', textAlign: 'center'}}>
                                Our collection of modular attachments allow for limitless configurations around our robots core module allowing you to customise your robot to your specific applications.
                            </p>
                        </Row>
                        <Row style={{padding: '10px'}}>
                            <h4 style={{margin: 'auto', textAlign: 'center'}}>
                                <u>Versatile</u>
                            </h4>
                        </Row>
                        <Row style={{padding: '10px'}}>
                            <p style={{margin: 'auto', textAlign: 'center'}}>
                                Our plug and play system allows for configurations to be changed in just a few seconds by anyone at any skill level. Just detatch the module you dont want and plug in the module you want. No more need to buy completely new robots when your needs change.
                            </p>
                        </Row>
                        <Row style={{padding: '10px'}}>
                            <h4 style={{margin: 'auto', textAlign: 'center'}}>
                                <u>Environmentally Friendly</u>
                            </h4>
                        </Row>
                        <Row style={{padding: '10px'}}>
                            <p style={{margin: 'auto', textAlign: 'center'}}>
                              Splitting robots into modules allows for less ewaste to be created for multiple reasons. The most public facing reason is that if a part of the robot breaks or you want to change configuration you just need to replace that specific component, 
                              not the whole robot. Other reasons include more efficient manufacturing capabilities due to being able to produce modules in high quantities.
                            </p>
                        </Row>
                        <Row style={{padding: '10px'}}>
                            <h4 style={{margin: 'auto', textAlign: 'center'}}>
                                <u>Low Costs</u>
                            </h4>
                        </Row>
                        <Row style={{padding: '10px'}}>
                            <p style={{margin: 'auto', textAlign: 'center'}}>
                                Our modular robotics system allows for the end user to buy a full robot setup for up to 80% of the cost of those at the current leading robotics companies.
                            </p>
                        </Row>
                        
                                             
                    </div>
                </div>                
            </div>
        );
    }
}
 
export default ModularRobotics;
