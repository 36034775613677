import React, { Component } from 'react';
class NotFound404 extends Component {
    state = {  }
    render() { 
        return ( 
            <h1>Not Found 404</h1>
         );
    }
}
 
export default NotFound404;