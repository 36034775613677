import React, { Component } from 'react';
import {Navbar, Nav, NavDropdown} from 'react-bootstrap'
import {Link} from "react-router-dom"
import Axios from 'axios'

class TopNav extends Component {
    state = { 
        expanded: false
     }
    toggle=()=>{
        this.setState({expanded: !this.state.expanded})
    }
    handleHostingClick=(url)=>{
        window.location.href = 'https://hosting.breezelabs.co.uk/' + url
    }
    handleBlogClick=()=>{
        Axios.post('https://hosting.breezelabs.co.uk/api/stats/page-visit', {referer: document.referrer ? document.referrer : 'searchbar', site: 'blog'})
        window.location.href = 'https://blog.breezelabs.co.uk'
    }
    render() { 
        return (
            <div>
                <Navbar expanded={this.state.expanded} onToggle={this.toggle} expand='md' bg="dark" variant="dark" fixed='top'>
                    <Navbar.Brand as={Link} to="/"><img alt='logo' style={{width: '150px', paddingTop: '15px'}} src={require('../logo.png')}></img></Navbar.Brand>
                    <Navbar.Toggle />
                    <Navbar.Collapse >
                    <Nav style={{width: '100%'}} className="justify-content-end">
                        <Nav.Link onClick={this.toggle} as={Link} to="/">Home</Nav.Link>
                        <Nav.Link onClick={()=>{this.handleBlogClick(); this.toggle()}}>Blog</Nav.Link>
                        <NavDropdown title='Hosting'>
                            <NavDropdown.Item onClick={()=>{this.toggle(); this.handleHostingClick('login')}}>Login</NavDropdown.Item>
                            <NavDropdown.Item onClick={()=>{this.toggle(); this.handleHostingClick('create-account')}}>Create Account</NavDropdown.Item>
                            <hr/>
                            <NavDropdown.Item onClick={this.toggle} as={Link} to="/hosting">About</NavDropdown.Item>
                            <NavDropdown.Item onClick={this.toggle} as={Link} to="/hosting/partner">Partner Programme</NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link onClick={this.toggle} as={Link} to="/on-demand-manufacturing">ODM</Nav.Link>
                        <Nav.Link onClick={this.toggle} as={Link} to="/modular-robotics">Robotics</Nav.Link>
                        <Nav.Link onClick={this.toggle} as={Link} to="/contact">Contact Us</Nav.Link>
                    </Nav>
                    </Navbar.Collapse>
                </Navbar>
            </div>
        );
    }
}

  
export default TopNav;
