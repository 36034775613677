import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css'
import ReactGA from 'react-ga'
ReactGA.initialize('UA-181353067-1')

ReactDOM.render(
  <App/>
  ,
  document.getElementById('root')
);
